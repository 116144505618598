<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("statistic.title") }}</h1>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-12">
        <Tabs :tabs="tabs" :currentTab="currentTab" @setTab="setCurrentTab" />
      </div>
    </div>
    <form action="/" method="get" class="statistics-form">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{
              $t("statistic.choose_period")
            }}</label>
            <div
              @click="setFocus"
              tabindex="-1"
              ref="picker"
              class="form-item__field multiselect"
            >
              <date-range-picker
                class="input-mask datepicker form-item__field"
                :ranges="ranges"
                :locale-data="dateRangePicker"
                :opens="'right'"
                :max-date="new Date()"
                v-model="selectedDate"
              >
                <template v-slot:input="picker">
                  {{ moment(picker.startDate).format("YYYY-MM-DD") }} -
                  {{ moment(picker.endDate).format("YYYY-MM-DD") }}
                </template>
              </date-range-picker>
              <div class="datepicker-arrow"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{ $t("statistic.format") }}</label>
            <div>
              <multiselect
                class="form-item__field"
                v-model="selectedFormat"
                :options="selectFormat"
                placeholder=""
                :allowEmpty="false"
                :show-labels="false"
                label="title"
                :preserve-search="true"
                track-by="title"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span v-if="option.title === 'All formats'">{{
                    $t("statistic.all_formats")
                  }}</span>
                  <span v-else>{{ option.title }}</span>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <span v-if="option.title === 'All formats'">{{
                    $t("statistic.all_formats")
                  }}</span>
                  <span v-else>{{ option.title }}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{
              $t("statistic.country")
            }}</label>
            <div>
              <multiselect
                v-if="selectCountries"
                class="form-item__field"
                v-model="selectedCountry"
                :options="selectCountries"
                :allowEmpty="false"
                placeholder=""
                :show-labels="false"
                :custom-label="customCountriesLabel"
                :label="`name_${$i18n.locale === 'ru' ? 'ru' : 'en'}`"
                :preserve-search="true"
                :track-by="`name_${$i18n.locale === 'ru' ? 'ru' : 'en'}`"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span v-if="option.name_en === 'All countries'">{{
                    $t("statistic.all_countries")
                  }}</span>
                  <span v-else>
                    {{
                      option[`name_${$i18n.locale === "ru" ? "ru" : "en"}`] +
                      ` (${option.iso})`
                    }}
                  </span>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <span v-if="option.name_en === 'All countries'">{{
                    $t("statistic.all_countries")
                  }}</span>
                  <span v-else>{{
                    option[`name_${$i18n.locale === "ru" ? "ru" : "en"}`] +
                    ` (${option.iso})`
                  }}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 1" type="text" v-model.trim="sub1" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 2" type="text" v-model.trim="sub2" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 3" type="text" v-model.trim="sub3" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-item pb-2">
            <div class="form-item__field">
              <input placeholder="SUB 4" type="text" v-model.trim="sub4" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{
              $t("statistic.browser")
            }}</label>
            <div>
              <multiselect
                class="form-item__field"
                v-model="selectedBrowsers"
                :options="selectBrowser"
                placeholder=""
                :allowEmpty="false"
                :show-labels="false"
                label="title"
                :preserve-search="true"
                track-by="title"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span v-if="option.title === 'All browsers'">{{
                    $t("statistic.all_browsers")
                  }}</span>
                  <span v-else>{{ option.title }}</span>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <span v-if="option.title === 'All browsers'">{{
                    $t("statistic.all_browsers")
                  }}</span>
                  <span v-else>{{ option.title }}</span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{
              $t("statistic.platform_2")
            }}</label>
            <multiselect
              class="form-item__field"
              v-model="selectedOs"
              :options="selectOS"
              :allowEmpty="false"
              placeholder=""
              :show-labels="false"
              label="title"
              :preserve-search="true"
              track-by="title"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <span v-if="option.title === 'All os'">{{
                  $t("statistic.all_platforms_2")
                }}</span>
                <span v-else>{{ option.title }}</span>
              </template>
              <template slot="option" slot-scope="{ option }">
                <span v-if="option.title === 'All os'">{{
                  $t("statistic.all_platforms_2")
                }}</span>
                <span v-else>{{ option.title }}</span>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="select-block pb-2">
            <label class="form-item__label">{{
              $t("statistic.platform")
            }}</label>

            <multiselect
              class="multiselect_multiple form-item__field"
              placeholder=""
              v-model="selectedSubAccounts"
              :options="selectSubAccounts"
              :close-on-select="false"
              label="name"
              track-by="id"
              :multiple="true"
              :preserve-search="true"
              :selectLabel="formatLabel('select_label')"
              :selectedLabel="formatLabel('selected_label')"
              :deselectLabel="formatLabel('deselect_label')"
              preselectFirst
            >
              <template slot="option" slot-scope="{ option }">
                <span v-if="option.id === 0">{{
                  formatLabel("statistic.all_platforms")
                }}</span>
                <span v-else>{{ option.name }}</span>
              </template>
              <template slot="noOptions">
                {{ $t("empty_list") }}
              </template>
            </multiselect>
          </div>
        </div>
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-4 d-flex align-center justify-content-start"
        >
          <button
            type="submit"
            @click.prevent="getStatistics"
            class="btn _mob100 _bg-green"
          >
            {{ $t("apply") }}
          </button>
        </div>
      </div>
    </form>
    <div class="sep mb-3"></div>
    <div class="c-table mt-5 mt-md-0">
      <div class="d-flex justify-content-between" v-show="!loadDataTable">
        <button class="buttons-csv dt-button buttons-csv buttons-html5">
          <vue-json-to-csv :json-data="csv">CSV </vue-json-to-csv>
        </button>
        <button
          class="buttons-csv dt-button buttons-csv buttons-html5 table-header-button"
          @click="$modal.show('tableColumn')"
        >
          <svg v-svg symbol="settings" size="0 0 14 14" role="info" />
        </button>
      </div>
      <div class="inner">
        <vue-good-table
          styleClass="table _no-wrap sortEnable tablesorter tablesorter-bootstrap"
          :columns="columnsNew"
          :rows="tableData"
        >
          <template slot="table-column" slot-scope="props">
            <span class="cursor-pointer" @click="toSort(props.column)">
              {{ formatLabel(props.column.label) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'first_column'">
              <span class="text-nowrap">{{ props.row.first_column }}</span>
            </span>
            <span v-if="props.column.field === 'clicks'">
              <span class="text-nowrap">{{ props.row.clicks }}</span>
            </span>
            <span v-if="props.column.field === 'uniq_clicks'">
              <span class="text-nowrap">{{ props.row.uniq_clicks }}</span>
            </span>
            <span v-if="props.column.field === 'ratio_percent'">
              <span class="text-nowrap"
                >1:{{ props.row.ratio ? props.row.ratio : 0 }}
                <template v-if="props.row.ratio">
                  ({{ ((1 / props.row.ratio) * 100).toFixed(1) }}%)
                </template>
                <template v-else> (0%) </template>
              </span>
            </span>
            <span v-if="props.column.field === 'subscriptions'">
              <span class="text-nowrap">{{ props.row.subscriptions }}</span>
            </span>
            <span v-if="props.column.field === 'unsubscriptions'">
              <span class="text-nowrap">{{ props.row.unsubscriptions }}</span>
            </span>
            <span v-if="props.column.field === 'traffic_back'">
              <span class="text-nowrap">{{ props.row.traffic_back }}</span>
            </span>
            <span v-if="props.column.field === 'traffic_back_lead'">
              <span class="text-nowrap">{{ props.row.traffic_back_lead }}</span>
            </span>
            <span v-if="props.column.field === 'traffic_back_profit'">
              <span class="text-nowrap">{{
                props.row.traffic_back_profit
              }}</span>
            </span>
            <span v-if="props.column.field === 'cpl_count'">
              <span class="text-nowrap">{{ props.row.cpl_count }}</span>
            </span>
            <span v-if="props.column.field === 'cpl_profit'">
              <span class="text-nowrap">{{ props.row.cpl_profit }}</span>
            </span>
            <span v-if="props.column.field === 'rs_clicks'">
              <span class="text-nowrap">{{
                props.row.rs_clicks | formatNumberWithSpaces
              }}</span>
            </span>
            <span v-if="props.column.field === 'rs_shows'">
              <span class="text-nowrap">{{
                props.row.rs_shows | formatNumberWithSpaces
              }}</span>
            </span>
            <span v-if="props.column.field === 'rs_ctr'">
              <span class="text-nowrap">{{ props.row.rs_ctr }}</span>
            </span>
            <span v-if="props.column.field === 'rs_income'">
              <span class="text-nowrap">{{ props.row.rs_income }}</span>
            </span>
            <span v-if="props.column.field === 'rs_avg_cpc'">
              <span class="text-nowrap">{{ props.row.rs_avg_cpc }}</span>
            </span>
            <span v-if="props.column.field === 'total_profit'">
              <span class="text-nowrap">{{ props.row.total_profit }}</span>
            </span>
          </template>
          <div class="d-flex justify-content-center" slot="emptystate">
            <template v-if="!loadDataTable">
              {{ $t("any_results") }}
            </template>
            <template v-else>
              <div class="d-flex justify-content-center">
                <img
                  :src="require('@/assets/images/rolling.svg')"
                  alt="loader"
                  class="loader"
                />
              </div>
            </template>
          </div>
        </vue-good-table>
      </div>
    </div>
    <modal
      :width="398"
      height="auto"
      name="tableColumn"
      classes="creative-modal"
      :scrollable="false"
    >
      <TableColumnModal
        @setSelected="setSelected"
        :columns="columnsNew"
        @closeModal="closeModalColumn"
      />
    </modal>
  </div>
</template>

<script>
import ServiceStatistics from "@/api/serviceStatistics";
import { mapGetters, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "@/assets/css/_daterangepicker.scss";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import TableColumnModal from "@/components/TableColumnModal.vue";
import Tabs from "@/components/statictics/Tabs.vue";
import VueJsonToCsv from "vue-json-to-csv";
import moment from "moment";

export default {
  name: "Statistic",
  components: { Tabs, DateRangePicker, VueJsonToCsv, TableColumnModal },
  beforeMount() {
    document.title = `Partners.House | ${this.$t("statistic.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("statistic.title")}`;
  },
  data() {
    return {
      csv: [],
      loadDataTable: false,
      tableData: [],
      totals: [],
      currentTab: "day",
      tabs: [
        "day",
        "hour",
        "format",
        "country",
        "os",
        "os_version",
        "browser",
        "subacc",
        "landing",
        "sub1",
        "sub2",
        "sub3",
        "sub4",
      ],
      selectedCountry: { name_ru: "Все страны", name_en: "All countries" },
      selectedBrowsers: {
        title: "All browsers",
        value: "All browsers",
      },
      selectedFormat: {
        title: "All formats",
        value: "All formats",
      },
      selectedOs: {
        title: "All os",
        value: "All os",
      },
      selectFormat: [
        {
          title: "All formats",
          value: "All formats",
        },
        {
          title: "Push",
          value: "Push",
        },
        {
          title: "InPage",
          value: "InPage",
        },
        {
          title: "POP",
          value: "Pop",
        },
      ],
      selectedSubAccounts: [
        { name: this.formatLabel("statistic.all_platforms"), id: 0 },
      ],
      sub1: "",
      sub2: "",
      sub3: "",
      sub4: "",
      sort: null,
      order: null,
      selectedDate: {
        startDate: this.moment().subtract(6, "days")._d,
        endDate: this.moment()._d,
      },
      dateRangePicker: {
        firstDay: 1,
        format: "yyyy.mm.dd",
        separator: "-",
        applyLabel: this.$t("apply"),
        cancelLabel: this.$t("cancel"),
        daysOfWeek: [
          this.$t("date.weeks.mo"),
          this.$t("date.weeks.tu"),
          this.$t("date.weeks.we"),
          this.$t("date.weeks.th"),
          this.$t("date.weeks.fr"),
          this.$t("date.weeks.sa"),
          this.$t("date.weeks.su"),
        ],
        monthNames: [
          this.$t("date.month.jan"),
          this.$t("date.month.feb"),
          this.$t("date.month.mar"),
          this.$t("date.month.apr"),
          this.$t("date.month.may"),
          this.$t("date.month.jun"),
          this.$t("date.month.jul"),
          this.$t("date.month.aug"),
          this.$t("date.month.sep"),
          this.$t("date.month.oct"),
          this.$t("date.month.nov"),
          this.$t("date.month.dec"),
        ],
      },
      ranges: {
        [this.$t("statistic.today")]: [moment()._d, moment()._d],
        [this.$t("statistic.yesterday")]: [
          moment().subtract(1, "days")._d,
          moment().subtract(1, "days")._d,
        ],
        [this.$t("statistic.last_seven_days")]: [
          moment().subtract(6, "days")._d,
          moment()._d,
        ],
        [this.$t("statistic.last_thirty_days")]: [
          moment().subtract(29, "days")._d,
          moment()._d,
        ],
        [this.$t("statistic.this_month")]: [
          moment().startOf("month")._d,
          moment().endOf("month")._d,
        ],
        [this.$t("statistic.last_month")]: [
          moment().subtract(1, "month").startOf("month")._d,
          moment().subtract(1, "month").endOf("month")._d,
        ],
        [this.$t("statistic.this_year")]: [
          moment().startOf("year")._d,
          moment()._d,
        ],
        [this.$t("statistic.last_year")]: [
          moment().subtract(1, "years").startOf("year")._d,
          moment().subtract(1, "years").endOf("year")._d,
        ],
      },
      columnsNew: [],
      firstNameColumn: "first_name",
      columns: [
        {
          label: "statistic.data",
          field: "first_column",
          thClass: `sortable spec-sort`,
          sortable: false,
          firstColumn: true,
          checked: true,
          disabled: true,
        },
        {
          label: "statistic.table.clicks",
          field: "clicks",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.uniq_clicks",
          field: "uniq_clicks",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.tb",
          field: "traffic_back",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.tb_leads",
          field: "traffic_back_lead",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.tb_profit",
          field: "traffic_back_profit",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.cpl_count",
          field: "cpl_count",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.cpl_profit",
          field: "cpl_profit",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.ratio",
          field: "ratio_percent",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.subscriptions",
          field: "subscriptions",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.unsubscriptions",
          field: "unsubscriptions",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.rs_clicks",
          field: "rs_clicks",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.rs_cpm",
          field: "rs_shows",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.rs_ctr",
          field: "rs_ctr",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.rs_income",
          field: "rs_income",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.rs_cpc",
          field: "rs_avg_cpc",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
        {
          label: "statistic.table.total_profit",
          field: "total_profit",
          thClass: `sortable spec-sort`,
          sortable: false,
          checked: true,
        },
      ],
    };
  },
  watch: {
    currentTab: {
      deep: true,
      handler() {
        this.clearFilterOrder();
        this.changeTableOrder();
        this.changeColumns();
        this.getStatistics();
      },
    },
    "$i18n.locale": function () {
      this.getStatistics();

      if (this.selectedSubAccounts.find((el) => el.id === 0)) {
        this.selectedSubAccounts.find((el) => el.id === 0).name =
          this.formatLabel("statistic.all_platforms");
      }
    },
    selectedSubAccounts: function () {
      if (this.selectedSubAccounts.find((el) => el.id === 0)) {
        this.selectedSubAccounts.find((el) => el.id === 0).name =
          this.formatLabel("statistic.all_platforms");
      }
    },
    tableData: {
      deep: true,
      handler(val) {
        if (val.length) {
          this.$nextTick(() => this.tableToJson());
        }
      },
    },
    order: function () {
      this.checkTableSpan();
    },
    sort: function () {
      this.checkTableSpan();
    },
  },
  computed: {
    ...mapGetters({
      selectBrowser: "statistics/getBrowsers",
      selectCountries: "statistics/getCountries",
      selectOS: "statistics/getOS",
      selectSubAccounts: "statistics/getSubAccounts",
      featureFlags: "user/getFeatureFlags",
    }),
    selectedSubAccIds() {
      const selectedArray = [];
      this.selectedSubAccounts.forEach((e) => {
        if (e.id !== 0) return selectedArray.push(e.id);
      });
      return selectedArray;
    },
    checkUnsubscriptions() {
      return !this.featureFlags.some((el) => el === "unsubscriptions");
    },
  },
  mounted() {
    this.changeColumns();
    this.getStatistics();
    this.setSubAccounts();
    if (this.featureFlags) {
      this.columnsNew = this.columns.filter((el) => {
        if (el.field === "unsubscriptions") {
          return this.featureFlags.some((el) => el === "unsubscriptions");
        } else {
          return true;
        }
      });
    }
    this.setStartColumn();
  },
  methods: {
    ...mapActions({
      setSubAccounts: "statistics/handleSubAccounts",
    }),
    clearFilterOrder() {
      this.order = null;
      this.sort = null;
    },
    customCountriesLabel({ name_en, name_ru, iso }) {
      return `${this.$i18n.locale === "ru" ? name_ru : name_en}` + ` (${iso})`;
    },
    moment: function (e) {
      return moment(e);
    },
    // columnModal begin
    closeModalColumn() {
      this.$modal.hide("tableColumn");
    },
    setSelected(evt) {
      this.columnsNew = evt;
      localStorage.setItem("campaignsColumns", JSON.stringify(this.columnsNew));
      this.closeModalColumn();
    },
    setStartColumn() {
      const campaignsColumnsStorage = localStorage.getItem("campaignsColumns");
      if (campaignsColumnsStorage && campaignsColumnsStorage !== "undefined") {
        this.columnsNew = JSON.parse(localStorage.getItem("campaignsColumns"));
      }
    },
    // columnModal end
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    setFocus() {
      this.$refs.picker.focus();
    },
    formatLabel(el) {
      return this.$t(el);
    },
    checkTableSpan() {
      const thElements = document.querySelectorAll("th.sortable"); // all sortable table columns
      thElements.forEach((thElement) => {
        const spanElement = thElement.querySelector("span"); // span of an current iteration th
        if (
          spanElement &&
          this.sort &&
          this.order &&
          spanElement.innerText ===
            this.$t(this.columns.find((el) => el.field === this.sort).label)
        ) {
          // check if an span text === current sort value text
          thElement.classList.remove(
            // remove oposite class
            this.order && this.order === "desc" ? "sorting-asc" : "sorting-desc"
          );
          thElement.classList.add(
            // add class to the current sort th
            this.order && this.order === "desc" ? "sorting-desc" : "sorting-asc"
          );
          return false;
        } else {
          // if no remove all classes
          thElement.classList.remove("sorting-desc", "sorting-asc");
        }
      });
    },
    toSort(val) {
      if (val.field === "real_name") {
        this.sort = this.currentTab;
      } else {
        this.order =
          this.sort === val.field
            ? this.order === "asc"
              ? "desc"
              : "asc"
            : "desc";
        this.sort = val.field;
      }
      this.getStatistics(val.field);
    },
    changeTableOrder() {
      switch (this.currentTab) {
        case "day":
          this.firstNameColumn = "statistic.data";
          break;
        case "hour":
          this.firstNameColumn = "statistic.tabs.hour";
          break;
        case "country":
          this.firstNameColumn = "statistic.tabs.country";
          break;
        case "format":
          this.firstNameColumn = "statistic.tabs.format";
          break;
        case "os":
          this.firstNameColumn = "statistic.tabs.os";
          break;
        case "os_version":
          this.firstNameColumn = "statistic.tabs.os_version";
          break;
        case "browser":
          this.firstNameColumn = "statistic.tabs.browser";
          break;
        case "subacc":
          this.firstNameColumn = "statistic.tabs.subacc";
          break;
        case "landing":
          this.firstNameColumn = "statistic.tabs.landing";
          break;
        case "sub1":
          this.firstNameColumn = "statistic.tabs.sub1";
          break;
        case "sub2":
          this.firstNameColumn = "statistic.tabs.sub2";
          break;
        case "sub3":
          this.firstNameColumn = "statistic.tabs.sub3";
          break;
        case "sub4":
          this.firstNameColumn = "statistic.tabs.sub4";
          break;
        default:
          this.firstNameColumn = "statistic.data";
      }
      this.columns[0].label = this.firstNameColumn;
    },
    changeColumns() {
      if (this.currentTab !== "subacc") {
        if (this.currentTab === "landing") {
          this.columnsNew = this.columns.filter((e) => {
            return !e.field.includes("rs_");
          });
        } else if (this.currentTab === "os_version") {
          this.columnsNew = this.columns.filter((e) => {
            return !(e.field === "rs_ctr" || e.field === "rs_shows");
          });
        } else if (
          this.currentTab === "sub1" ||
          this.currentTab === "sub2" ||
          this.currentTab === "sub3" ||
          this.currentTab === "sub4"
        ) {
          this.columnsNew = this.columns.filter((e) => {
            return !(e.field === "rs_ctr" || e.field === "rs_shows");
          });
        } else {
          this.columnsNew = this.columns.filter((e) => {
            return e.field !== "feed";
          });
        }
      } else if (this.columnsNew === "feed") {
        this.columnsNew = this.columns.filter((e) => {
          return e.field !== "feed" && e.field !== "shows" && e.field !== "ctr";
        });
      } else {
        this.columnsNew = this.columns;
      }
    },
    setTable() {
      this.tableData.forEach((e) => {
        if (!e.first_column) e.first_column = this.$t("total");
      });
      const index = this.columns.findIndex((x) => x.firstColumn === true);
      this.setFirstPosition(this.columns, index, 0);
    },
    setFirstPosition(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    },
    checkNull(val) {
      return val ? val : null;
    },
    getStatistics() {
      const params = {
        start_date: this.moment(this.selectedDate.startDate).format(
          "YYYY-MM-DD"
        ),
        end_date: this.moment(this.selectedDate.endDate).format("YYYY-MM-DD"),
        group_by: this.currentTab,
        country: this.selectedCountry.iso,
        format:
          this.selectedFormat.value === "All formats"
            ? null
            : this.selectedFormat.value,
        browser:
          this.selectedBrowsers.value === "All browsers"
            ? null
            : this.selectedBrowsers.value,
        sub_accounts: this.selectedSubAccIds,
        os: this.selectedOs.value === "All os" ? null : this.selectedOs.value,
        sub1: this.checkNull(this.sub1),
        sub2: this.checkNull(this.sub2),
        sub3: this.checkNull(this.sub3),
        sub4: this.checkNull(this.sub4),
        sort: this.sort,
        order: this.order,
      };
      this.tableData = [];
      this.loadDataTable = true;
      ServiceStatistics.getStatistics(params)
        .then((res) => {
          if (res && res.length) {
            this.tableData = res;
            this.totals = res.totals;
            this.setTable();
          }
        })
        .finally(() => {
          this.loadDataTable = false;
        });
    },
    tableToJson() {
      let table = document.getElementById("vgt-table");
      let data = [];
      let headers = [];
      for (let i = 0; i < table.rows[0].cells.length; i++) {
        headers[i] = table.rows[0].cells[i].innerText
          .toLowerCase()
          .replace(/ /gi, "");
      }
      for (let i = 1; i < table.rows.length; i++) {
        let tableRow = table.rows[i];
        let rowData = {};
        for (let j = 0; j < tableRow.cells.length; j++) {
          rowData[headers[j]] = tableRow.cells[j].innerText;
        }
        data.push(rowData);
      }
      this.csv = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item__field:focus {
  .input-mask {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 1px #000109 !important;
  }

  .datepicker-arrow {
    transform: scale(1, -1);
  }
}

.loader {
  height: 40px;
}

.table-header-button {
  svg {
    fill: #5d5d68;
  }
}

.input-mask {
  &::v-deep {
    .reportrange-text {
      position: absolute;
      display: flex;
      align-items: center;
      border-color: transparent;
      left: 0;
      height: 45px;
      font-weight: 500;
      color: #212529;
      background: transparent;
      padding: 5px 10px 5px 45px;
    }

    .daterangepicker {
      top: 45px;
    }
  }
}

.multiselect {
  &::v-deep {
    .multiselect__tags {
      flex-direction: column;
      align-items: flex-start;
    }

    .multiselect__input {
      display: none;
      margin-bottom: 0;
      box-shadow: none !important;
    }
  }

  &.multiselect--active {
    &::v-deep {
      .multiselect__input {
        display: block;
      }
    }
  }
}

.buttons-csv {
  background-color: #f3f4f9;
  color: #5d5d68;
  padding: 6px 14px;
  border-radius: 12px;
  outline: none;
  border: 0;
  transition: all 0.3s;
}
</style>
