<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"
        ><svg v-svg symbol="x" size="0 0 17 17" role="presentation"
      /></span>
    </button>
    <div class="modal-body">
      <div class="title-h2 pb-2">{{ $t("columns") }}</div>
      <div class="row">
        <div
          class="col-6 form-item__field"
          v-for="(item, i) in newColumns"
          :key="i"
        >
          <label class="check-label" :disabled="item.disabled">
            <div class="check-box-block">
              <span
                class="checkSpan"
                :class="{ active: item.checked, disabled: item.disabled }"
              >
                <svg
                  v-svg
                  symbol="check"
                  v-if="item.checked"
                  size="0 0 15 15"
                  role="info"
                  class="check"
                />
              </span>
              <span class="text">{{ formatLabel(item.label) }}</span>
              <input
                :disabled="item.disabled"
                hidden
                v-model="item.checked"
                type="checkbox"
                class="debugCB"
                @change="toggleSelectOnce(item)"
              />
            </div>
          </label>
        </div>
      </div>
      <button class="link" @click="toggleAll(!setFilter)">
        <strong>{{ setAllTitle }}</strong>
      </button>
      <div class="sep _h20"></div>
      <div class="row">
        <div class="col-6 col-md-auto">
          <button class="btn _mob100" @click="$emit('setSelected', newColumns)">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
    <notifications group="error" />
  </div>
</template>
<script>
import "@/assets/css/_tubs.scss";
import "@/assets/css/_code.scss";
import "@/assets/css/_modal.scss";

export default {
  name: "TableColumnsModal",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newColumns: {},
    };
  },
  computed: {
    setAllTitle() {
      return this.setFilter ? this.$t("turn_all_off") : this.$t("turn_all_on");
    },
    setFilter() {
      let counter = 0;
      for (let i = 0; i < this.newColumns.length; i++) {
        if (this.newColumns[i].checked) {
          counter++;
        }
      }
      return counter > 1;
    },
  },
  methods: {
    toggleSelectOnce(evt) {
      evt.hidden = !evt.checked;
    },
    removeAllChange() {
      this.$emit("removeAllChanges");
    },
    toggleActive() {
      this.$emit("toggleAll", !this.setFilter);
    },
    toggleAll(evt) {
      console.log("toggleAll");
      if (evt) {
        this.newColumns.forEach((e) => (e.checked = true));
        this.newColumns.forEach((e) => (e.hidden = false));
      } else {
        this.newColumns.forEach((e) =>
          e.field !== "first_column" ? (e.checked = false) : (e.checked = true)
        );
        this.newColumns.forEach((e) =>
          e.field !== "first_column" ? (e.hidden = true) : (e.hidden = false)
        );
      }
    },
    formatLabel(el) {
      return this.$t(el);
    },
  },
  created() {
    this.newColumns = JSON.parse(JSON.stringify(this.columns));
    localStorage.setItem("campaignsColumns", JSON.stringify(this.columns));
  },
};
</script>
<style lang="scss" scoped>
.link {
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 600;
  color: #141414;
  border: 0;
  background-color: transparent;
  border-bottom: 1px dashed rgba(17, 68, 17, 0.267);
  padding: 0;
  outline: none;
  offset: none;
  background: none;
}

.check-box-block {
  display: flex;
  align-items: stretch;
  gap: 0 4px;
}

.checkSpan {
  position: relative;
  width: 20px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: #f3f4f9;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  cursor: pointer;

  &.active {
    background: #f0faf9;

    svg {
      fill: #38807d;
    }
  }

  &.disabled {
    background: #f5f5f5;

    svg {
      fill: #5d5d68;
    }
  }

  .check {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
}
</style>